import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of, Subject, takeUntil } from 'rxjs';
import { SymbolService } from '../../services/symbol.service';
import { DataGenerator } from '../../store/data-generator/data-generator.model';
import { DataGeneratorSelectors } from '../../store/data-generator/data-generator.selector';
import { DataGeneratorState, LoadSymbolDataGenerators } from '../../store/data-generator/data-generator.state';
import { DataGeneratorEditingComponent } from './data-generator-editing/data-generator-editing.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Prompt } from '../../model/prompt.model';
import { UserState } from '../../store/user/user.state';

const DEFAULT_PROMPT = `# INSTRUCTIONS
You are a senior financial analyst analysing news items to identify information which may impact the profitability of a company.
When providing a summary, you are careful to date the information in order to provide context. News have a tendency of losing importance with time, but some information can impact a company's value for a long time. It is your role to ensure that the summary is as informative and relevant as possible.

You are provided with the summary you produced previously. Make sure that your response incorporates elements from the previous summary which you think are still relevant now.

Ignore news items which provide no clear signal.

Provide your summary as bullet points with the key points. Each bullet point should start with a timestamp of the news item. The total length of your summary should not exceed 200 words.

Your summary should be tailored to news which are strongly relevant to the future valuation of {Name}.

It is currently {CurrentRunDate}. The previously summary was generated at {LastRunDate}.

# EXISTING SUMMARY
{SelfSummary}

# NEWS
{TopNews|NEW|<SYMBOL>}

# UPDATED SUMMARY`


@Component({
  selector: 'app-prompt-items',
  templateUrl: './data-generator-list.component.html',
  styleUrl: './data-generator-list.component.scss'
})
export class DataGeneratorListComponent implements OnInit, OnDestroy  {
  private destroy$ = new Subject<void>();

  isCreating$ = this.store.select(DataGeneratorSelectors.isCreating);
  isAuthenticated$ = this.store.select(UserState.isAuthenticated);

  symbol: string = '';
  sectionOpened: boolean = false;
  generators: Observable<DataGenerator[]> = of([]);

  constructor(
    private symbolService: SymbolService,
    private store: Store,
    private dialog: MatDialog)
  {}

  ngOnInit(): void {
    this.symbolService.symbol$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((symbol) => {
      this.symbol = symbol;
      this.generators = this.store.select(DataGeneratorSelectors.selectBySymbol(symbol));
      this.store.dispatch(new LoadSymbolDataGenerators(symbol));

      this.generators.subscribe((generators) => {
        if (generators.length > 0) {
          this.sectionOpened = true;
        }
      });
    });
  }

  toggle() {
    this.sectionOpened = !this.sectionOpened;
  }

  clonePrompt(g: DataGenerator): MatDialogRef<DataGeneratorEditingComponent> {
    const gAny = g as any;
    return this.dialog.open(DataGeneratorEditingComponent, {
      data: {
        generator: {
          ...g,
          name: `Copy of ${g.name}`,
          prompt: {
            ...g.prompt,
            template_body: gAny.prompt.templateBody,
          },
          run_freq: gAny.runFreq,
          started_at: gAny.startedAt,
        } as DataGenerator
      },
      panelClass: 'prompt-editing-dialog',
    });
  }

  newPrompt(): MatDialogRef<DataGeneratorEditingComponent> {
    const prompt = DEFAULT_PROMPT.replace('<SYMBOL>', this.symbol);
    return this.dialog.open(DataGeneratorEditingComponent, {
      data: {
        generator: {
          news_summariser_id: '',
          name: 'New News Summarizer',
          description: '',
          prompt: {
            template_body: prompt,
          } as Prompt,
          symbol: '',
          isOwner: true,
          ownerId: '',
          summary: '',
          run_freq: '45 15 * * 1-5',
          started_at: new Date(),
          timezone: undefined
        } as DataGenerator
      },
      panelClass: 'prompt-editing-dialog',
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete(); // completes the observable to avoid memory leaks
  }
}
