import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from "@angular/router";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import {MatMenuModule} from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTableModule  } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { NgApexchartsModule } from "ng-apexcharts";

import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';

import { firebaseApp, db, analytics, auth, functions, database, isProduction } from '../environments/environment';
import { provideAnalytics } from '@angular/fire/analytics';
import { provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore } from '@angular/fire/firestore';
import { provideFunctions } from '@angular/fire/functions';
import { provideDatabase } from '@angular/fire/database';
import { AuthModule, provideAuth } from '@angular/fire/auth';
import { FirebaseUIModule} from 'firebaseui-angular';
import { firebaseUiAuthConfig } from 'src/app/auth.config';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { FIREBASE_OPTIONS } from '@angular/fire/compat';

import * as Sentry from "@sentry/angular";

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { SearchBarComponent } from './search/search-bar/search-bar.component';
import { DetailsComponent } from './details/details.component';
import { SearchResultItemComponent } from './search/search-result-item/search-result-item.component';
import { SearchComponent } from './search/search.component';
import { PromptListComponent } from './details/prompt/prompt-list.component';
import { PromptEditingComponent } from './details/prompt/prompt-editing/prompt-editing.component';
import { PromptInputsComponent } from './details/prompt/prompt-inputs/prompt-inputs.component';
import { HomeOverviewTabsComponent } from './home/home-overview-tabs/home-overview-tabs.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { MetricsOverviewComponent } from './details/metrics-overview/metrics-overview.component';
import { PromptViewingComponent } from './details/prompt/prompt-viewing/prompt-viewing.component';
import { InteractiveChartComponent } from './details/interactive-chart/interactive-chart.component';
import { FeedbackCannyComponent } from './components/feedback-canny.component';
import { ConfirmDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { PromptsOverviewComponent } from './admin/prompts-overview/prompts-overview.component';

import { BacktestRunComponent } from './details/backtest/backtest-run/backtest-run.component';
import { BacktestStatusComponent } from './details/backtest/backtest-status/backtest-status.component';
import { BacktestDialogComponent } from './details/backtest/backtest-dialog/backtest-dialog.component';
import { NewsOverviewComponent } from './home/news-overview/news-overview.component';
import { GaHomeComponent } from './home/ga-home.component';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfileComponent } from './profile/profile.component';
import { ConsentDialogComponent } from './legal/consent/consent-dialog.component';
import { NotAuthorizedComponent } from './legal/not-authorized/not-authorized.component';
import { CommonModule, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { StatisticsComponent } from './statistics/statistics.component';
import { PromptPositionsComponent } from './statistics/prompt-positions/prompt-positions.component';
import { PromptPerformanceComponent } from './statistics/prompt-performance/prompt-performance.component';
import { PromptPerformanceChangeComponent } from './statistics/prompt-performance-change/prompt-performance-change.component';
import { SafeNumberPipe, SafePercentPipe } from './pipes/safe-number.pipe';
import { PromptsState } from './store/prompt/prompt.state';
import { UserPromptsComponent } from './profile/user-prompts/user-prompts.component';
import { DataGeneratorListComponent } from './details/data-generator/data-generator-list.component';
import { DataItemState } from './store/data-item/data-item.state';
import { DataGeneratorState } from './store/data-generator/data-generator.state';
import { UserState } from './store/user/user.state';
import { PredictionState } from './store/prediction/prediction.state';
import { GaAutocompleteDialogComponent } from './components/ga-autocomplete/ga-autocomplete-dialog.component';
import { GaAutocompleteDropdownComponent } from './components/ga-autocomplete/ga-autocomplete-dropdown.component';
import { GaAutocompleteDirective } from './components/ga-autocomplete/ga-autocomplete.directive';
import { GaAutocompleteComponent } from './components/ga-autocomplete/ga-autocomplete.component';
import { DataGeneratorEditingComponent } from './details/data-generator/data-generator-editing/data-generator-editing.component';
import { DataGeneratorDetailComponent } from './details/data-generator/data-generator-detail/data-generator-detail.component';
import { LandingComponent } from './home/landing/landing.component';
import { ConsultingPageComponent } from './home/consulting/consulting.component';
import { ProductPageComponent } from './home/product/product.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    SearchBarComponent,
    HomeOverviewTabsComponent,
    InteractiveChartComponent,
    DetailsComponent,
    SearchResultItemComponent,
    SearchComponent,
    PromptListComponent,
    PromptEditingComponent,
    PromptInputsComponent,
    LoginPageComponent,
    MetricsOverviewComponent,
    PromptViewingComponent,
    FeedbackCannyComponent,
    ConfirmDialogComponent,
    PromptsOverviewComponent,
    BacktestRunComponent,
    BacktestStatusComponent,
    BacktestDialogComponent,
    GaHomeComponent,
    NewsOverviewComponent,
    ProfileComponent,
    ConsentDialogComponent,
    NotAuthorizedComponent,
    StatisticsComponent,
    PromptPositionsComponent,
    PromptPerformanceComponent,
    PromptPerformanceChangeComponent,
    PromptPositionsComponent,
    UserPromptsComponent,
    SafeNumberPipe,
    SafePercentPipe,
    DataGeneratorListComponent,
    DataGeneratorDetailComponent,
    DataGeneratorEditingComponent,
    GaAutocompleteDialogComponent,
    GaAutocompleteDropdownComponent,
    GaAutocompleteDirective,
    GaAutocompleteComponent,
    LandingComponent,
    ConsultingPageComponent,
    ProductPageComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    AppRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatGridListModule,
    MatListModule,
    MatExpansionModule,
    MatTabsModule,
    MatIconModule,
    MatProgressBarModule,
    MatSelectModule,
    MatCardModule,
    MatChipsModule,
    MatTooltipModule,
    MatSidenav,
    MatSidenavContainer,
    MatSidenavContent,
    MatCheckbox,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    AuthModule,
    MatProgressSpinnerModule,
    NgApexchartsModule,
    provideAnalytics(() => analytics),
    provideFirebaseApp(() => firebaseApp),
    provideFirestore(() => db),
    provideFunctions(() => functions),
    provideAuth(() => auth),
    provideDatabase(() => database),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    NgxsModule.forRoot([
      UserState,
      PromptsState,
      DataItemState,
      DataGeneratorState,
      PredictionState
    ], {
      developmentMode: isProduction
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      disableTimeOut: false,
      timeOut: 3000,
    })
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    PercentPipe,
    provideAnimationsAsync(),
    { provide: FIREBASE_OPTIONS, useValue: firebaseApp.options },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      })
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
