import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { PredictionSelectors } from '../../../store/prediction/prediction.selector';
import { PredictionBack, PredictionNext } from '../../../store/prediction/prediction.state';
import { DataGenerator, NewsSummaryStatus } from '../../../store/data-generator/data-generator.model';
import { Prediction } from '../../../store/prediction/prediction.model';
import { DeleteDataGenerator } from '../../../store/data-generator/data-generator.state';

@Component({
  selector: 'app-data-generator-detail',
  templateUrl: './data-generator-detail.component.html',
  styleUrls: ['./data-generator-detail.component.scss'],
})
export class DataGeneratorDetailComponent implements OnInit {
  @Input() generator!: DataGenerator;
  @Output() clone = new EventEmitter<DataGenerator>();

  get id() {
    return this.generator.news_summariser_id;
  }

  get currentStatus() {
    return this.generator?.status ?? NewsSummaryStatus.unavailable;
  }

  get statusTooltip() {
    return this.getStatusTooltip(this.currentStatus);
  }

  currentPrediction!: Prediction | null;
  isFirst: boolean = false;
  isLast: boolean = false;
  isLoading: boolean = false;
  statusEnum = NewsSummaryStatus;

  constructor(private store: Store) {}

  ngOnInit(): void {
    const id = this.generator.news_summariser_id;

    this.store
      .select(PredictionSelectors.selectedForGenerator(id))
      .subscribe((prediction) => (this.currentPrediction = prediction));

    this.store
      .select(PredictionSelectors.isFirstPrediction(id))
      .subscribe((isFirst) => (this.isFirst = isFirst));

    this.store
      .select(PredictionSelectors.isLastPrediction(id))
      .subscribe((isLast) => (this.isLast = isLast));

    this.store
      .select(PredictionSelectors.isLoading(id))
      .subscribe((isLoading) => (this.isLoading = isLoading));
  }

  previous() {
    this.store.dispatch(new PredictionBack(this.id));
  }

  next() {
    this.store.dispatch(new PredictionNext(this.id));
  }

  deleteGenerator() {
    this.store.dispatch(new DeleteDataGenerator(this.id));
  }

  cloneGenerator() {
    this.clone.emit(this.generator);
  }

  private getStatusTooltip(status: NewsSummaryStatus): string {
    switch (status) {
      case NewsSummaryStatus.queued:
        return 'Prediction queued';
      case NewsSummaryStatus.running:
        return 'Prediction is running';
      case NewsSummaryStatus.interrupted:
        return 'Prediction interrupted';
      case NewsSummaryStatus.ready:
        return 'Prediction ready';
      case NewsSummaryStatus.error:
        return 'Prediction encountered an error';
      default:
        return 'Status unavailable';
    }
  }
}
