import { State, Action, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FirebaseHttpService } from '../../services/firebase-http.service';
import { DataGenerator } from './data-generator.model';
import { collection, Firestore, onSnapshot, or, query, QueryFieldFilterConstraint, where } from '@angular/fire/firestore';
import { UserState } from '../user/user.state';
import { LoadPredictionsForGenerator } from '../prediction/prediction.state';

// Actions
export class CreateDataGenerator {
  static readonly type = '[DataGenerator] Create';
  constructor(public payload: DataGenerator) {}
}

export class LoadDataGenerator {
  static readonly type = '[DataGenerator] Read';
  constructor(public id: string) {}
}

export class DeleteDataGenerator {
  static readonly type = '[DataGenerator] Delete';
  constructor(public id: string) {}
}

export class LoadSymbolDataGenerators {
  static readonly type = '[DataGenerator] Load Symbol';
  constructor(public symbol: string) {}
}

export class LoadUserDataGenerators {
  static readonly type = '[DataGenerator] Load User';
  constructor() {}
}

export interface DataGeneratorStateModel {
  dataGenerators: DataGenerator[];
  isCreating: boolean;
}

@State<DataGeneratorStateModel>({
  name: 'dataGenerators',
  defaults: {
    dataGenerators: [],
    isCreating: false
  }
})
@Injectable()
export class DataGeneratorState {
  private symbolUnsubscribe: any;
  private userUnsubscribe: any;

  constructor(
    private store: Store,
    private db: Firestore,
    private firebaseHttp: FirebaseHttpService,
    private toastr: ToastrService)
  {}

  @Action(LoadSymbolDataGenerators)
  async loadSymbolDataGenerators(
    ctx: StateContext<DataGeneratorStateModel>,
    { symbol }: LoadSymbolDataGenerators) {

    const constraints = [where('tags', 'array-contains', `symbol:${symbol}`)];

    this.symbolUnsubscribe?.();
    const [q, s] = this.loadDataGenerators(ctx,  constraints);
    this.symbolUnsubscribe = s;

    return q;
  }

  @Action(LoadUserDataGenerators)
  async loadUserDataGenerators(
    ctx: StateContext<DataGeneratorStateModel>) {

    const userId = this.getUserId();
    const constraints = [where('ownerId', '==', userId)];

    this.userUnsubscribe?.();
    const [q, s] = this.loadDataGenerators(ctx,  constraints);
    this.userUnsubscribe = s;

    return q;
  }

  loadDataGenerators(
    ctx: StateContext<DataGeneratorStateModel>,
    constraints: QueryFieldFilterConstraint[]) {

    const collectionRef = collection(this.db, 'data_generators');
    const q = query(collectionRef, ...constraints);

    let subscription = null;
    const promise = new Promise((resolve, reject) => {
      subscription = onSnapshot(q, (snapshot) => {
        let generators: DataGenerator[] = this.snapshotToGenerators(snapshot);
        generators = this.mergeItems(ctx.getState, generators);

        // Update the state
        ctx.patchState({
          dataGenerators: generators
        });

        // Load predictions for each generator
        const ids = generators.map(g => g.news_summariser_id);
        ctx.dispatch(new LoadPredictionsForGenerator(ids));

        resolve(generators);
      }, (error) => {
        if (this.toastr) this.toastr.error(error.message, 'Error syncing generators');
          reject(error);
      });
    });

    return [promise, subscription];
  }

  getUserId() {
    return this.store.selectSnapshot(UserState.getUserId) || '';
  }

  snapshotToGenerators(snapshot: any): DataGenerator[] {
    const userId = this.getUserId();
    let generators: any[] = [];
    snapshot.forEach((doc: any) => {
      const data: any = doc.data();
      const generator = {
        ...data,
        id: doc.id,
        isOwner: data.ownerId === userId
      };
      generators.push(generator);
    });
    return generators;
  }

  mergeItems(getState: () => DataGeneratorStateModel, items: DataGenerator[]): DataGenerator[] {
    // Get existing prompts that are not in the new list
    const existingItems = getState().dataGenerators
      .filter(p1 => !items.some(p2 => p2.news_summariser_id === p1.news_summariser_id));

    // Merge
    const allItems = [...existingItems, ...items];

    // Return
    return allItems;
  }

  @Action(CreateDataGenerator)
  async createDataGenerator(
    { patchState, getState }: StateContext<DataGeneratorStateModel>,
    { payload }: CreateDataGenerator) {

    try {
      patchState({ isCreating: true });
      const response = await this.firebaseHttp.call(
        'api',
        {
          method: 'news_aggregator_manager',
          action: 'create',
          name: payload.name,
          description: payload.description,
          template_body: payload.prompt.template_body,
          run_freq: payload.run_freq,
          started_at: payload.started_at,
          timezone: payload.timezone,
          symbol: payload.symbol
        }
      );
      const generator = response.data as DataGenerator;

      this.toastr.success('Data Generator created successfully', 'Success');
      patchState({
        dataGenerators: [...getState().dataGenerators, generator]
      });
    } catch (error: any) {
      this.toastr.error('Error creating data generator', 'Error');
    } finally {
      patchState({ isCreating: false });
    }
  }

  @Action(LoadDataGenerator)
  async readDataGenerator(
    { getState, patchState }: StateContext<DataGeneratorStateModel>,
    { id }: LoadDataGenerator) {

    try {
      const response = await this.firebaseHttp.call(
        'api',
        { method: 'news_aggregator_manager',action: 'read', id }
      );

      const generator = response.data as DataGenerator;
      const generators = getState().dataGenerators.filter(g => g.news_summariser_id !== id);
      patchState({ dataGenerators: [...generators, generator] });
    } catch (error: any) {
      this.toastr.error('Error reading data generator', 'Error');
    }
  }

  @Action(DeleteDataGenerator)
  async deleteDataGenerator(
    { getState, patchState }: StateContext<DataGeneratorStateModel>,
    { id }: DeleteDataGenerator) {

    try {
      patchState({
        dataGenerators: getState().dataGenerators.filter(g => g.news_summariser_id !== id)
      });

      await this.firebaseHttp.call('api', { method: 'news_aggregator_manager', action: 'delete', id });
      this.toastr.success('Data Generator deleted successfully', 'Success');
    } catch (error: any) {
      this.toastr.error('Error deleting data generator', 'Error');
    }
  }
}
