<h2 mat-dialog-title>Configure backtest</h2>
<div mat-dialog-content class="container">
  <form *ngIf="!showOptions" class="left" [formGroup]="runConfigForm">
    <mat-form-field appearance="fill">
      <mat-label>Time (HH:mm)</mat-label>
      <input matInput  formControlName="runTime" type="time" />
    </mat-form-field>

    <div formGroupName="backtestConfig">
      <mat-form-field appearance="fill">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startPicker" formControlName="startDate"/>
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endPicker" formControlName="endDate" />
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>

      <h3>Trading triggers</h3>
      <div *ngFor="let trigger of triggers; let i = index" class="component-item">
        <span>{{ trigger.name }}</span>
        <button mat-icon-button color="warn" (click)="removeTrigger(i)">
            <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button mat-raised-button color="primary" *ngIf="!showOptions" (click)="addComponent()">Add</button>
      <span *ngIf="tradeCount() as tradeCount">These triggers will result in {{tradeCount}} trades.</span>
    </div>

    <mat-dialog-actions align="end">
      <button mat-raised-button (click)="cancel()" [disabled]="hasOperation">
        Cancel
      </button>
      <button mat-raised-button color="primary" cdkFocusInitial (click)="run()" [disabled]="hasOperation">
        Run
      </button>
    </mat-dialog-actions>

    <div *ngIf="data.prompt.backtest_count > 0" class="warning">
      The existing backtest will be lost
    </div>
  </form>

  <div *ngIf="showOptions" class="right">
    <h2>Configure trigger</h2>
    <mat-label>Trigger type</mat-label>
    <mat-select [(value)]="selectedSchema" (selectionChange)="onSchemaChange()">
      <mat-option *ngFor="let schema of schemas" [value]="schema">{{ schema }}</mat-option>
    </mat-select>

    <jsonforms
      [data]="triggerConfig"
      [schema]="schema"
      [uischema]="uischema"
      [renderers]="renderers"
      [validationMode]="validationMode"
      [ajv]="ajv"
      (dataChange)="onDataChange($event)"
    ></jsonforms>

    <div mat-dialog-actions align="end">
      <button mat-raised-button (click)="closeTrigger()">
        Cancel
      </button>
      <button mat-raised-button color="primary" cdkFocusInitial (click)="submitTrigger()">
        Add
      </button>
    </div>
  </div>
</div>
