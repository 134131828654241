import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmDialogComponent } from '../../../components/confirmation-dialog/confirmation-dialog.component';
import { Prompt } from '../../../model/prompt.model';
import { SymbolService } from '../../../services/symbol.service';
import { Store } from '@ngxs/store';
import { DataGenerator } from '../../../store/data-generator/data-generator.model';
import { CreateDataGenerator } from '../../../store/data-generator/data-generator.state';


@Component({
  selector: 'app-data-generator-editing',
  templateUrl: './data-generator-editing.component.html',
  styleUrl: './data-generator-editing.component.scss',
})
export class DataGeneratorEditingComponent implements OnInit, OnDestroy {
  // Ten most common timezones
  timezones: string[] = [];

  generator: DataGenerator = {
    news_summariser_id: '',
    name: '',
    description: '',
    prompt: {
      template_body: '',
    } as Prompt,
    symbol: '',
    isOwner: true,
    ownerId: '',
    summary: '',
    type: '',
    run_freq: '45 15 * * 1-5',
    started_at: new Date(),
    timezone: undefined
  };

  selectedTabIndex = 0;

  private destroy$ = new Subject<void>();
  public hasChanges: boolean = false;

  constructor(
    private store: Store,
    private symbolService: SymbolService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DataGeneratorEditingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { generator: DataGenerator }) {
      this.generator = data.generator;
    }

  ngOnInit(): void {
    this.symbolService.symbol$
      .pipe(takeUntil(this.destroy$))
      .subscribe((symbol) => {
        this.generator.symbol = symbol;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async save() {
    await this.store.dispatch(new CreateDataGenerator(this.generator));
    this.dialogRef.close();
  }

  insertInput(item: any) {
    this.generator.prompt.template_body += `${item.name}: \n${item.examples[0]}`;
  }

  handleCancel(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      data: { message: 'You have unsaved changes. Do you want to exit without saving?' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === false) return;
      this.dialogRef.close();
    });
  }

}
