<h3>

  <div class="prompt-name" matTooltip="Generator name">{{ generator.name }}</div>

  <div class="status-indicator" [matTooltip]="statusTooltip">
    <mat-spinner *ngIf="isLoading || currentStatus === statusEnum.running" diameter="20"></mat-spinner>
    <mat-icon *ngIf="!isLoading && currentStatus === statusEnum.ready" color="primary">check_circle</mat-icon>
    <mat-icon *ngIf="!isLoading && currentStatus === statusEnum.error" color="warn">error</mat-icon>
    <mat-icon *ngIf="!isLoading && currentStatus === statusEnum.interrupted" color="warn">block</mat-icon>
  </div>

  <button mat-icon-button [matMenuTriggerFor]="menu" class="options-button">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="deleteGenerator()" *ngIf="generator.isOwner">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
    <button mat-menu-item (click)="cloneGenerator()">
      <mat-icon>content_copy</mat-icon>
      <span>Clone</span>
    </button>
  </mat-menu>

</h3>

<div class="navigation">
  <button mat-icon-button (click)="previous()" [disabled]="isFirst" class="chevron-left">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <button mat-icon-button (click)="next()" [disabled]="isLast" class="chevron-right">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>

<p class="news-content">{{ currentPrediction?.summary || 'No prediction available' }}</p>

<div class="news-date">{{ currentPrediction?.valid_at | date:'medium' }} ({{ currentPrediction?.valid_at | date:'z' }})</div>
