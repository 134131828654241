import { Component, ViewChild, OnInit, OnDestroy, untracked } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PromptService as PromptService } from 'src/app/services/prompt.service';
import { PromptsService } from 'src/app/services/prompts.service';
import { SymbolService } from 'src/app/services/symbol.service';
import { PromptEditingComponent } from './prompt-editing/prompt-editing.component';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { BacktestDialogComponent } from '../backtest/backtest-dialog/backtest-dialog.component';
import { doc, getDoc, DocumentSnapshot, Firestore } from '@angular/fire/firestore';
import { ToastrService } from 'ngx-toastr';
import { Prompt } from '../../model/prompt.model';
import { UserState } from 'src/app/store/user/user.state';
import { Store } from '@ngxs/store';

enum FilterValue {
  All = 'All',
  Mine = 'Mine',
}
@Component({
  selector: 'app-prompt-list',
  templateUrl: './prompt-list.component.html',
  styleUrl: './prompt-list.component.scss',
})
export class PromptListComponent implements OnInit, OnDestroy {
  dataSource = new MatTableDataSource<Prompt>([]);
  displayedColumns: string[] = [
    'expand',
    'name',
    'ar',
    'are',
    'backtest',
    'active',
    'actions'
  ];
  displayFlex = 'flex';
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
  @ViewChild(MatSort) sort: MatSort | null = null;

  data: Prompt[] = [];
  filterValues: FilterValue[] = [FilterValue.All, FilterValue.Mine];
  selectedFilterValue: FilterValue = FilterValue.All;
  selectedIndex: number = 0;
  isExpanded: any = {};
  private destroy$ = new Subject<void>();

  isAuthenticated$ = this.store.select(UserState.isAuthenticated);

  constructor(
    private store: Store,
    private db: Firestore,
    private promptsService: PromptsService,
    private promptService: PromptService,
    private symbolService: SymbolService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    combineLatest([this.promptsService.prompts$, this.promptService.promptId$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([prompts, promptId]) => {
        this.data = prompts.map((p: Prompt) => {
          return {
            ...p,
            selected: p.promptId === promptId,
          } as Prompt;
        });

        // Initialize filter
        this.applyFilter(this.selectedFilterValue);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(filterValue: FilterValue) {
    this.selectedFilterValue = filterValue;
    this.selectedIndex = this.filterValues.findIndex((f) => f === filterValue);
    if (filterValue === FilterValue.All) {
      this.setData(this.data);
      return;
    }
    if (filterValue === FilterValue.Mine) {
      const filtereData = this.data.filter((prompt: any) => prompt.isOwner);
      this.setData(filtereData);
      return;
    }

    this.setData(this.data);
  }

  setData(data: Prompt[]) {
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.selectPage();
  }

  selectPage() {
    if (!this.paginator) return;

    const selectedIndex = this.dataSource.data.findIndex((p: any) => p.selected);
    if (selectedIndex < 0) return;

    const pageSize = this.paginator.pageSize;
    const pageIndex = Math.floor(selectedIndex / pageSize);
    this.paginator.pageIndex = pageIndex;
  }

  select(prompt: any) {
    // Always select the prompt via the route
    this.router.navigate(['ticker', this.symbolService.symbolSubject.value, prompt.promptId]);
  }

  edit(prompt: any): MatDialogRef<PromptEditingComponent> {
    this.select(prompt);
    return this.openPromptDialog(prompt);
  }

  openPromptDialog(prompt: Prompt): MatDialogRef<PromptEditingComponent> {
    return this.dialog.open(PromptEditingComponent, {
      data: {
        prompt: prompt
      },
      panelClass: 'prompt-editing-dialog',
    });
  }

  view(prompt: any) {
    this.select(prompt);
  }

  backtest(prompt: any) {
    this.dialog.open(BacktestDialogComponent, {
      data: { prompt: prompt, promptId: prompt.promptId },
      height: '500px',
      width: '450px',
    });
  }

  copy(event: Event, prompt: any) {
    event.stopPropagation();

    console.log('Copying prompt:', prompt);
    const newPrompt: Prompt = {
      promptId: undefined,
      name: `${prompt.name} (copy)`,
      description: prompt.description,
      target_ticker: prompt.target_ticker,
      template_body: prompt.template_body,
      template_footer: prompt.template_footer,
      template_header: prompt.template_header,
      isOwner: true,
      active: false
    };

    return this.openPromptDialog(newPrompt);
  }

  newPrompt() {
    const isAuthenticated = this.store.selectSnapshot(UserState.isAuthenticated);
    if (!isAuthenticated) {
      this.router.navigate(['login']);
      return;
    }

    const symbol = this.symbolService.symbolSubject.value;
    this._loadDefaultPrompt().then((data: any) => {
      console.log('Default prompt:', data.data());
      const prompt: Prompt = {
        ...data.data(),
        promptId: undefined,
        target_ticker: symbol,
        name: 'New Prompt',
        description: '',
        template_body: '',
        isOwner: true,
        backtestStatus: undefined,
      };
      delete prompt['owner_id'];
      this.openPromptDialog(prompt);
    });
  }

  delete(prompt: any) {
    this.promptService.delete(prompt);
    this.promptsService.remove(prompt.promptId);
  }

  toggleActive($event: any, prompt: any) {
    $event.stopPropagation();

    // Check if the user has reached the maximum number of active prompts
    const isMaxPromptReached = this.store.selectSnapshot(UserState.isMaxPromptsReached);
    if (!prompt.active && isMaxPromptReached) {
      this.toastr?.error(
        'You have reached your maximum of active prompts. Upgrade to increase capacity.',
        'Error activating prompt'
      );
      return;
    }

    prompt = this.promptService.toggleActive(prompt);
  }

  toggleExpanded(prompt: any) {
    this.isExpanded[prompt.promptId] = !this.isExpanded[prompt.promptId];
    this.promptService.loadRun(prompt, false);

    // Workaround for a bug in safari rendering of display flex
    // https://forums.developer.apple.com/forums/thread/737214
    // Probably this will resolve itself in the future when
    // updating angular material
    this.displayFlex = 'none';
    setTimeout(() => {
      this.displayFlex = 'flex';
    }, 1);
  }

  onPage(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async _loadDefaultPrompt(): Promise<DocumentSnapshot> {
    return getDoc(doc(this.db, 'prompts', '_default'));
  }
}
